import React, { useState } from "react";
import { toast } from "react-toastify";
import * as appUtils from "../helpers/appUtils";
import * as API from "../api/index";
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router";
import { CheckCircle } from "react-feather";
const initialData = {
  name: "",
  email: "",
  image: "",
  password: "",
  details: "",
  subject: "",
};
const OrderFrom = () => {
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [imageData, setImageData] = useState("");
  const [OTP, setOTP] = useState("");

  // ? image uploading
  const imageUploading = (e) => {
    let images = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setImageData(reader.result);
    };
    reader.readAsDataURL(images);
  };
  const handalerChnages = (e) => {
    const { name, value } = e.target;
    setLoading(false);
    switch (name) {
      case "email":
        setErrorEmail("");
        setErrorMsg(false);
        break;
      case "details":
        setErrorName("");
        break;
      case "subject":
        setErrorPassword("");
        break;
      default:
    }
    setFormData({ ...formData, [name]: value });
  };
  const sendNow = async () => {
    setLoading(true);
    let flag = validate();
    if (!flag) {
      setLoading(false);
      return;
    }
    try {
      const reqObj = {
        name: "",
        emailId: formData.email,
        password: "1234567899",
      };
      const response = await API.user_registration(reqObj);
      if (response.data.success) {
        const headerObj = {
          Authorization: `Bearer ${response.data.token_code}`,
        };
        localStorage.setItem("_tokenCode", JSON.stringify(headerObj));
        setIsEmail(1);
        localStorage.setItem("_userId", response.data.data._id);
      }
      console.log("response", response);
      console.log("reqObj", reqObj);
    } catch (error) {}
  };

  const opt_verification = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        emailId: formData.email,
        otp: OTP,
      };
      const response = await API.forgotPassword(reqObj);
      console.log("response", response);
      if (response.data.success === 1) {
        const reqObj = {
          image: imageData,
          details: formData.details,
          studentId: localStorage.getItem("_userId"),
          subject: formData.subject,
        };
        console.log("reqObj", reqObj);
        const response = await API.add_enquiry(reqObj, header);
        console.log("response", response);
        if (response.data.success === 1) {
          toast(response.data.msg, {
            position: "top-right",
            autoClose: 5000,
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/account");
        }
      } else {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          type: "error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {}
  };

  //VALIDATE-INPUT
  const validate = () => {
    const { email, details, subject } = formData;
    let flag = true;

    let validateEmail = appUtils.validateEmail(email);
    if (validateEmail === 1) {
      setErrorEmail({
        field: "email",
        message: "",
      });
    }
    if (!(validateEmail === 1)) {
      let msg = "";
      if (validateEmail === 0) {
        msg = "Please enter your email address.";
      } else {
        msg = "That doesn't look like an email address.";
      }
      setErrorEmail({
        field: "email",
        message: msg,
      });
      flag = false;
    }

    //   Firt name
    let validateName = appUtils.validateName(details);
    if (validateName === 1) {
      setErrorName({
        field: "details",
        message: "",
      });
    }
    if (!(validateName === 1)) {
      let msg = "";
      if (validateName === 0) {
        msg = "Please enter your details";
      } else {
        msg = "That doesn't look like a name.";
      }
      setErrorName({
        field: "details",
        message: msg,
      });
      flag = false;
    }

    let validateSubject = appUtils.validateName(subject);
    if (validateSubject === 1) {
      setErrorPassword({
        field: "subject",
        message: "",
      });
    }
    if (!(validateSubject === 1)) {
      let msg = "";
      if (validateSubject === 0) {
        msg = "Please enter your subject";
      } else {
        msg = "That doesn't look like a name.";
      }
      setErrorPassword({
        field: "subject",
        message: msg,
      });
      flag = false;
    }

    return flag;
  };

  return (
    <>
      <div class="form-column">
        <div
          class="inner-columna wow fadeInRight animated"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
          <div class="color-box"></div>
          {isEmail === 0 ? (
            <>
              <h3 className="mb-3">Place your order here!</h3>
              <div class="trial-form">
                <div className="row">
                  <div className="col-md-12">
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handalerChnages}
                      />
                      {errorEmail.field === "email" && (
                        <p className="formErrorAlrt">{errorEmail.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col-md-12">
                    <div class="form-group border_textArea">
                      <textarea
                        name="details"
                        cols="30"
                        rows="2"
                        placeholder="Text here"
                        value={formData.details}
                        onChange={handalerChnages}
                      ></textarea>
                      <div class="form-group up_load">
                        <input
                          className="choose_option"
                          type="file"
                          name="image"
                          onChange={imageUploading}
                          placeholder="Choose Assistant Deadline"
                          required=""
                        />
                        <div className="input_overlay">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#777777"
                            class="bi bi-file-earmark-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                            <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                          </svg>{" "}
                          Upload
                          <div className="chekp">
                            {imageData ? <CheckCircle /> : ""}
                          </div>
                        </div>
                      </div>
                      {errorName.field === "details" && (
                        <p className="formErrorAlrt">{errorName.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col-md-12"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handalerChnages}
                    placeholder="Subject"
                  />
                  {errorPassword.field === "subject" && (
                    <p className="formErrorAlrt">{errorPassword.message}</p>
                  )}
                </div>

                <div class="form-group">
                  <button
                    onClick={sendNow}
                    type="submit"
                    class="theme-btn submit-btn"
                  >
                    <span class="txt">Send now</span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h3 className="mb-3">Email Verification</h3>
              <div className="otpInput">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
              </div>
              <div class="form-group">
                <button
                  onClick={opt_verification}
                  type="submit"
                  class="commonButton mt-5"
                >
                  <span class="txt">Send now</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderFrom;
