import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Carousal = () => {
  return (
    <>
      <div className="desktp_view">
        <OwlCarousel
          className="testimonial-carousel"
          loop
          margin={10}
          autoplay={true}
          items={2}
          responsiveClass={true}
          autoplaySpeed={5000}
        >
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      <div className="mobile_view">
        <OwlCarousel
          className="testimonial-carousel"
          loop
          margin={10}
          autoplay={true}
          items={1}
          responsiveClass={true}
          autoplaySpeed={5000}
        >
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="text">
                  We turn your experts into thought leaders by getting their
                  voices heard in the market and by building credibility and brand
                  advocates amongst industry influencers from the media, analyst
                  community
                </div>
                <div class="author-info">
                  <div class="info-inner">
                    <div class="author-image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                    <h6>Mahfuz Riad</h6>
                    <div class="designation">Online Teacher</div>
                  </div>
                </div>
                <div class="quote-icon flaticon-quote-2"></div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default Carousal;
