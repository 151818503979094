import React from "react";
import Container from "./Container";

const Base = ({setIsLogin}) => {
  return (
    <>
      <Container setIsLogin={setIsLogin} />
    </>
  );
};

export default Base;
